// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-review-tsx": () => import("./../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-search-tsx": () => import("./../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-video-tsx": () => import("./../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */)
}

